<template><!-- Header Start -->
  <header class="site-header site-header--menu-left site-header--absolute">
    <div class="container">
      <nav class="navbar site-navbar offcanvas-active navbar-expand-lg  px-0 mobile-d-css">
        <!-- Brand Logo-->
        <div class="navbar-top d-flex justify-content-between header-control">
          <div class="brand-logo">
            <a :href="baseurl">
              <!-- light version logo (logo must be black)-->
              <!--<h3>Interactive <span>Intro</span></h3>-->
              <!--  <img class="nav-logo" :src="users.logo" />-->
              <img class="nav-logo" src="@/assets/front/image/main-logo.png" />
            </a>
          </div>

          <div class="outer-box-con">
              <!-- mobile code html -->
            <div class="dropdown-box-mo">
              <label for="toggle" class="user-title-cls"><i  class="fa fa-bars"></i></label>
              <input type="checkbox" id="toggle" aria-hidden="true" />
              <ul class="mobile-box-control">
                <li>
                  <router-link to="/Profile" class="btn btn-primary add-comman-btn">Edit Profile</router-link>
                </li>

                <li>
                  <router-link to="/affiliate/profile" class="btn btn-primary add-comman-btn">Affiliate Dashboard</router-link>
                </li>

                <li>
                  <router-link to="/affiliate/account" class="btn btn-primary add-comman-btn">Account Settings</router-link>
                </li>

                <li>
                  <router-link to="/affiliate/payment" class="btn btn-primary add-comman-btn">Payment History</router-link>
                </li>
              </ul>
            </div>
            <div v-if="user.loggedIn" class="dropdown-box-mo">
              <label for="toggle1" class="profile-title"><i  class="fa fa-user"></i>{{ user.data.displayName }}</label>
              <input type="checkbox" id="toggle1" aria-hidden="true" />
              <ul class="mobile-box-control">
                <li>
                  <router-link to="/Profile" class="btn btn-primary add-comman-btn"> Sign Out </router-link>
                </li>
              </ul>
            </div>
            <!-- mobile code html -->
          </div>

          <!-- dashboard code html -->
          <ul class="navbar-nav dashboard-box-control">
            <li>
              <router-link to="/Profile" class="btn btn-primary add-comman-btn" data-aos="fade-up"
                data-aos-duration="1200" data-aos-delay="400">Edit Profile</router-link>
            </li>

            <li>
              <router-link to="/affiliate/profile" class="btn btn-primary add-comman-btn" data-aos="fade-up"
                data-aos-duration="1200" data-aos-delay="400">Affiliate Dashboard</router-link>
            </li>

            <li>
              <router-link to="/affiliate/account" class="btn btn-primary add-comman-btn" data-aos="fade-up"
                data-aos-duration="1200" data-aos-delay="400">Account Settings</router-link>
            </li>

            <li>
              <router-link to="/affiliate/payment" class="btn btn-primary add-comman-btn" data-aos="fade-up"
                data-aos-duration="1200" data-aos-delay="400">Payment History</router-link>
            </li>

            <!-- <li>
                  <router-link to="/affiliate/selling" class="btn btn-primary add-comman-btn" data-aos="fade-up" data-aos-duration="1200"
                    data-aos-delay="400">Selling</router-link>
                </li> -->

            <!-- <li>
                  <router-link to="/insider" class="btn btn-primary add-comman-btn" data-aos="fade-up" data-aos-duration="1200"
                    data-aos-delay="400">Intro Insider</router-link>
                </li> -->

          </ul>
          <!-- dashboard code html -->

          

          <div class="header-btn">
            <a href="#" class="hide_menu"><i class="fa fa-times"></i></a>
            <!-- <a href="#" class="sign_in">Dubai &nbsp;<i class="fa fa-angle-down"></i></a>--->

            <!-- <router-link :to="{ name: 'front-blogs' }" class="nav-link">Blog</router-link> -->

            <template v-if="user.loggedIn">
              <router-link :to="{ name: 'userProfile' }" class="sign_in mobile-devices-h" id="profile_tag">
                <div>{{ user.data.displayName }}</div>
                <div class="profile_name ">Profile</div>
              </router-link>
              <!-- <router-link to="/" class="sign_in"></router-link> -->
              <a class="nav-link mobile-devices-h" @click.prevent="signOut">Sign out</a>
            </template>
            <template v-else>
              <router-link to="/signin" class="sign_in">Sign in </router-link>
              <router-link to="/registration" class="sign_up">Sign up </router-link>
            </template>

            <router-link to="/cart" class="cart-icon">
              <img class="shoping-card-icon" src="@/assets/front/image/shopping-cart.png" />
              <span>{{ count }}</span>
            </router-link>
          </div>
          <!-- <div class="header-btn">
                <a href="#" class="hide_menu"><i class="fa fa-times"></i></a>


                <template v-if="user.loggedIn">
                  <router-link :to="{ name: 'userProfile' }" class="sign_in">Profile</router-link>
                  <router-link to="/" class="sign_in">{{
                      user.data.displayName
                  }}</router-link>
                  <a class="nav-link" @click.prevent="signOut">Sign out</a>
                </template>
                <template v-else>
                  <router-link to="/signin" class="sign_in">Sign in </router-link>
                  <router-link to="/registration" class="sign_up">Sign up </router-link>
                </template>

                <router-link to="/cart" class="cart-icon">
                  <img class="shoping-card-icon" src="@/assets/front/image/shopping-cart.png" />
                  <span>{{ count }}</span>
                </router-link>
              </div> -->
        </div>
      </nav>
    </div>
  </header>
<!-- Heade End --></template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import { db } from "@/main";
import { initialAbility } from '@/libs/acl/config'
import store from '@/store';


export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  data: function () {
    return {
      options: [],
      users: {},
      loggedIn: '',
      name: "",
      count: "",
      counts: "",
      accountMenuOption: "/",
      key: localStorage.getItem("slug"),
      baseurl: "/en/" + localStorage.getItem("slug"),
    };
  },
  validations: {
    name: {},
  },
  created: function () {
    document.getElementById("google_translate_element").style.display = "block";
    this.accountMenuOption = this.$router.history.current.fullPath;
    this.getCountries();
    this.getUser();
    this.getCartCount();
    this.loggedIn = localStorage.getItem("userSessionId") || false;
  },
  watch: {
    // Watch for route changes
    $route(route) {
      // Change the value of the selected option
      this.accountMenuOption = route.path;
      console.log('page 166');
      console.log(route);
    },
  },
  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    submit: function () {
      console.log(this.name);
      this.$router.push("/search?search=" + this.name);
    },

    onchange: function () {
      db.collection("countries")
        .where("status", "==", "1")
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            if (doc.data().slug == localStorage.getItem("slug")) {

              localStorage.setItem("sluga", doc.data().lng);
              localStorage.setItem("slugaa", doc.data().lat);
              localStorage.setItem("ciddata", doc.id);

            }

          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      console.log("page 1");
      console.log(this.key);
      localStorage.setItem("slug", this.key);
      this.baseurl = "/en/" + this.key;
      this.$router.push(this.baseurl);
      //document.getElementById("map").innerHTML.reload
      // alert(window.location.href);
      let text = window.location.href;
      let result = text.includes("?search=");
      if (result) {
        //this.$router.replace("/?search=")
        // location.reload();

      }
    },

    async getCartCount() {
      var uid = localStorage.getItem("userSessionId");
      var randomId = localStorage.getItem("randomId");

      if (uid != null) {
        console.log("yes1");
        console.log(uid);
        this.query = db.collection("cart").where("uid", "==", uid);
        this.querys = db.collection("orders").where("uid", "==", uid);
      } else {
        console.log("no1");
        this.query = db.collection("cart").where("random_id", "==", randomId);
      }

      const snapshot = await this.query.get();
      this.count = snapshot.size;

      //console.log( this.count );

      const snapshots = await this.querys.get();
      this.counts = snapshots.size;
      console.log(this.counts);

      // if(counts > 0){
      //  console.log('rrrr');
      //   console.log(counts);
      // }
    },

    getCountries() {

      db.collection("countries")
        .where("status", "==", "1")
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            if (doc.data().slug == localStorage.getItem("slug")) {

              localStorage.setItem("sluga", doc.data().lng);
              localStorage.setItem("slugaa", doc.data().lat);
              localStorage.setItem("ciddata", doc.id);
            }
            //console.log(doc.id, " => ", doc.data());
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      this.options = [];

      db.collection("countries")
        .where("status", "==", "1")
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            this.options.push({
              id: doc.id,
              name: doc.data().country,
              slug: doc.data().slug,
            });
            //console.log(doc.id, " => ", doc.data());
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    getUser() {
      var uid = localStorage.getItem("userSessionId");
      db.collection("users")
        .doc(uid)
        .get()
        .then((doc) => {
          // console.log('hhhh99');
          //console.log(doc.id, " => ", doc.data());
          this.users = doc.data();
          console.log('Users' + this.users);

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // localStorage.removeItem(isloggedIn);
          localStorage.removeItem('Ustatus');
          localStorage.removeItem("userSessionId");
          localStorage.removeItem("randomId");
          // Remove userData from localStorage
          localStorage.removeItem('userData')

          // Reset ability
          this.$ability.update(initialAbility)

          store.dispatch('logOut');
          console.log(store.getters.user)
          console.log('logout');

          // Redirect to login page
          this.$router.push({ name: 'Home' })
        })

    },
  },
};


</script>

<style>
#profile_tag {
  flex-direction: column;
}

.profile_name {
  color: #6e6b7b;
  font-size: 10px;
}
.btn-primary {
    border-color: #03e1bc  !important;
    background-color: #03e1bc  !important;
}

@import "../../assets/front/css/bootstrap.css";

@import "../../assets/front/fonts/icon-font/css/style.css";

@import "../../assets/front/fonts/typography-font/typo.css";

@import "../../assets/front/fonts/fontawesome-5/css/all.css";

@import "../../assets/front/plugins/aos/aos.min.css";

@import "../../assets/front/plugins/fancybox/jquery.fancybox.min.css";

@import "../../assets/front/plugins/nice-select/nice-select.min.css";

@import "../../assets/front/plugins/slick/slick.min.css";

@import "../../assets/front/plugins/date-picker/css/gijgo.min.css";

@import "../../assets/front/css/main.css";

@import "../../assets/front/css/languageHeader.css";
/* @import "../../assets/front/css/languageHeader.css"; */

@media only screen and (min-width: 1200px) {
  .outer-box-con {
    display: none !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 1199px){
  label.profile-title {
    margin-right: 88px;
}

}
@media screen and (max-width: 1199px) {

  .goog-te-combo {
    font-size: 10px !important;
  }

  .header-btn {
    /* margin-left: 64px !important; */
    padding-left: 50% !important;
    top: 70px;
  }

  a.nav-link {
    left: 5% !important;
  }

  nav.navbar.site-navbar {
    padding: 0px 0px 50px !important;
  }

  .navbar-top.d-flex.justify-content-between {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .brand-logo {
    padding-bottom: 10px;
    max-width: 100px !important;
  }

  ul.navbar-nav li a {
    font-size: 12px !important;
    height: 100% !important;
    padding: 0;
    margin: 0;
  }

  body>div.frame>div>header>div>nav>div>ul>li:first-child>a {
    height: auto;
    min-width: auto;
    font-size: 16px;
  }

  .navbar-nav {
    /* padding-top: unset; */
  }

  .profilepage {
    top: 80px !important;
    padding-bottom: 30% !important;
  }

  h1.text-white.pt-3.mt-n5.me-2 {
    font-size: 2rem;
  }

  .site-header--absolute {
    background: #fff !important;
  }

  /* only mobile device */
  .navbar-top.d-flex.justify-content-between.header-control {
      flex-direction: row;
  }
  .dropdown-box-mo {
    position: relative !important;
  }
  .dropdown-box-mo i.fa {
      font-size: 16px;
      width: 40px;
      height: 40px;
      background: #03e1bc;
      border: 1px solid #03e1bc;
      display: inline-block;
      padding: 10px 10px;
      border-radius: 50%;
      text-align: center;
      color: #fff;
  }
  .dropdown-box-mo ul.mobile-box-control {
      position: absolute;
      right: 0;
      top: 50px;
      background: #fff;
      padding: 15px;
      box-shadow: 1px 1px 15px #a8999942;
      z-index: 1;
  }
  ul.mobile-box-control {
    display: none;
  }
  ul.mobile-box-control li a {
      width: 100%;
      padding: 0;
      margin: 5px 0 !important;
      font-size: 14px;
  }

  ul.mobile-box-control li {
      width: 100%;
      margin: 0;
      display: block;
  }
  #toggle:checked + .mobile-box-control {
    display: block;
  }
  #toggle1:checked + .mobile-box-control {
    display: block;
  }

  .dropdown-box-mo #toggle, .dropdown-box-mo #toggle1 {
    display: none
  }
  .brand-logo {
      max-width: 50%;
      width: 100%;
  }
  .outer-box-con {
      max-width: 50%;
      width: 100%;
      display: flex;
      justify-content: end;
  }
  nav.navbar.site-navbar.offcanvas-active.navbar-expand-lg.px-0.mobile-d-css {
      padding: 8px 0 0 0 !important;
  }
  label.user-title-cls i.fa {
      margin-right: 10px;
  }
  label.profile-title i.fa {
      margin-right: 5px;
  }
  .dashboard-box-control {
    display: none !important;
  }
  .mobile-devices-h {
    display: none !important;
  }
  .header-btn {
    justify-content: end;
    margin: 0 -20px !important;
    padding: 0 !important;
}
.profilepage {
  padding: 20px 0 !important;
}
  /* only mobile device */
}

@media only screen and (min-width: 360px) and (max-width: 576px) {
  .navbar-nav {
    padding-top: 0px !important;
  }
  .site-header--absolute {
    background: #fff !important;
  }
  #google_translate_element {
    z-index: 1 !important;
    left: 1rem !important;
    position: absolute !important;
    top: 77px !important;
  }
}
a.btn.btn-primary.add-comman-btn.router-link-exact-active.router-link-active.aos-init.aos-animate {
    background-color: #f88300!important;
}
a.btn.btn-primary.add-comman-btn.router-link-active.aos-init.aos-animate {
    background: #f88300!important;
}
</style>
